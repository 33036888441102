
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { AnalyticsEvent } from "@evercam/shared/types"

const CUSTOM_THUMBNAIL = "custom"

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    thumbnailData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedThumbnail: null,
      customThumbnail: null,
      isUpdating: false,
    }
  },
  computed: {
    internalDialogValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
  },
  watch: {
    thumbnailData: {
      handler() {
        this.selectedThumbnail = this.thumbnailData?.cameraExid
        if (this.thumbnailData.custom) {
          this.customThumbnail = this.thumbnailData?.image
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleCameraItemClick(camera) {
      this.selectedThumbnail = camera.id
    },
    selectCustomThumbnail() {
      this.selectedThumbnail = CUSTOM_THUMBNAIL
    },
    closeDialog() {
      this.$analytics.saveEvent(
        AnalyticsEvent.ProjectSettingsOverviewCloseUpdateThumbnailDialog
      )
      this.internalDialogValue = false
    },
    async updateThumbnail() {
      if (!this.selectedThumbnail) {
        console.warn("No thumbnail selected")

        return
      }

      this.$analytics.saveEvent(
        AnalyticsEvent.ProjectSettingsOverviewUpdateThumbnail
      )

      let params = {}
      if (this.selectedThumbnail === CUSTOM_THUMBNAIL && this.customThumbnail) {
        params = { uploadedThumbnail: this.customThumbnail }
      } else {
        params = { cameraExid: this.selectedThumbnail }
      }

      try {
        this.isUpdating = true
        await EvercamApi.projects.updateThumbnail(this.project.exid, params)
        this.closeDialog()
        setTimeout(() => {
          this.$emit("thumbnail-updated")
        }, 1000)
      } catch (error) {
        console.error("Error updating thumbnail", error)
      } finally {
        this.isUpdating = false
      }
    },
    handleCustomThumbnail() {
      this.$analytics.saveEvent(
        AnalyticsEvent.ProjectSettingsOverviewUploadNewThumbnail
      )

      const imageInput = document.createElement("input")
      imageInput.type = "file"
      imageInput.accept = "image/*"
      imageInput.click()

      imageInput.onchange = (e) => {
        const file = e.target.files[0]
        if (file) {
          const reader = new FileReader()
          reader.onload = (event) => {
            this.customThumbnail = event.target.result
            this.selectedThumbnail = CUSTOM_THUMBNAIL
          }
          reader.readAsDataURL(file)
        }
      }
    },
  },
}
